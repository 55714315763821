import React, { useEffect } from "react"
import { navigate } from "gatsby"

const NotFoundPage = ({ data }) => {
    useEffect(() => {
        navigate("/")
    }, [])

    return null
}

export default NotFoundPage
